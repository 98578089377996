.card_container {
  display: flex;
  flex-direction: row;
}

.card_home {
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
}

.card_home:hover {
  transform: translateY(-5px);
}

.card_home-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.card_home-content {
  padding: 10px;
}

.card_home-title {
  margin: 0;
  font-size: 1.5rem;
}

.card_home-description {
  margin: 8px 0;
  color: #555;
}

.card_home-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.card_home-button:hover {
  background-color: #0056b3;
}

.exchange-list-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
}
